@font-face {
  font-family: 'gyparody';
  src: URL('gyparody.ttf');
}

@font-face {
  font-family: 'swiss';
  src: URL('Swiss\ 911\ Compressed\ Regular.otf');
}

@font-face {
  font-family: 'korinna';
  src: URL('Korinna-Regular.otf');
}

html{
  font-family: 'korinna';
}

html{
  background: 
  linear-gradient(rgb(26, 26, 230),rgb(26, 26, 230)

  /* , #0779E4 */
  ),
   url("test11.jpeg") no-repeat center center fixed; 
  background-blend-mode: multiply;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.App{
  width: max-content;
  margin: auto;
  text-align: center;
}

html{
  color: white;
}

/* @font-face {
  font-family: myFirstFont;
  src: url(./Roboto-Regular.ttf);
} */

/* body {
  font-family: 'gyparody';
} */

.cell{
  border: 1px solid white;
  height: 120px;
  width: 150px;
  color: rgb(255, 232, 158);
  /* background-color: #0779E4; */
  /* background-color: 	rgba(18,89,161,.65); */
  background-color: rgba(49,49,233);
  margin: auto;
  text-align: center;
  font-size: 45px;
  font-family: 'swiss';
}

.cell:hover{
  cursor: pointer;
}

.emptyCell{
  border: 1px solid white;
  height: 118px;
  width: 148px;
  /* background-color: #0779E4; */
  background-color: rgba(49,49,233);

  margin: auto;
  text-align: center;
  font-size: 18px;
  color: white;
}

.category{
  -webkit-text-stroke-width: .1px;
  -webkit-text-stroke-color: black;
  font-family: 'swiss';
  font-size: 24px;
  height: 120px;
  width: 150px;
  color: white;
  /* background-color: #0779E4; */
  /* background-color: rgba(18,89,161,.65); */
  background-color: rgba(49,49,233);

  margin: auto;
  text-align: center;
  border: 1px solid white;
}

.answerInput{
  color: rgb(66, 66, 66);
  margin-top: 15px;
  font-size: 24px;
  height:30px;
  width: 300px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.inputBlock{
  display: block;
  height:30px;
}

.answerInput:focus{
  outline: none;
}

.submitBetButton{
  margin-left: 5px;
  font-size: 24px;
  height: 36px;
  color: rgb(66, 66, 66);
  background: transparent;
  border: none;
}

.skipButton{
  position: relative;
  top: 8px;
  margin-left: 5px;
  /* color: #0779E4; */
  /* color:rgba(49,49,233, .75); */
  color: white;
  /* color: black; */
  background: transparent;
  border: none;
}

.skipButton:hover{
  cursor: pointer;
}

.currQuestion{
  font-family: 'korinna';
  -webkit-text-stroke-width: .1px;
  -webkit-text-stroke-color: black;
  font-size: 50px;
  height: 720px;
  width: 900px;
  color: white;
  /* border: 2px solid white; */
  /* background-image: linear-gradient(to bottom left, #0779E4, #07b0e4); */
  /* background-color: #0779E4; */
  background-color: rgba(49,49,233);
  margin: auto;
  text-align: center;
  /* border: none; */
  border: 1px solid white;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}

.aboutContainer{
  font-family: 'korinna';
  -webkit-text-stroke-width: .1px;
  -webkit-text-stroke-color: black;
  font-size: 50px;
  height: 720px;
  width: 900px;
  color: white;
  background-color: rgba(49,49,233);
  margin: auto;
  text-align: center;
  border: 1px solid white;
  /* display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column; */
}

.currQ{
  text-shadow: 1px 1px black;
  margin: auto;
  text-align: center;
  max-width: 800px;
}

.currCategory{
  text-shadow: 1px 1px black;
  display: revert;
  position: absolute;
  top: 125px;
  font-size: 50px;
  margin: auto;
  text-align: center;
  color: white;
  min-width: 900px;
  max-width: 900px;
}

.board{
  margin-top: 15px;
}

.currContainer{
  margin-top: 15px;
}

.correctScreen{
  text-shadow: 1px 1px black;
  font-family: 'korinna';
  font-size: 50px;
  height: 720px;
  width: 900px;
  color: white;
  border: 1px solid white;
  /* background-color: #0779E4; */
  background-color: rgba(49,49,233);
  /* background-image: linear-gradient(to bottom left, #0779E4, #07b0e4); */
  margin: auto;
  margin-top: 15px;
  text-align: center;
  /* border: none; */
  /* border: 2px solid white; */
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}

.incorrectScreen{
  text-shadow: 1px 1px black;
  font-family: 'korinna';
  font-size: 50px;
  height: 720px;
  width: 900px;
  color: white;
  /* border: 1px solid white; */
  /* background-color: #0779E4; */
  background-color: rgba(49,49,233);
  /* background-image: linear-gradient(to bottom left, #0779E4, #07b0e4); */
  margin: auto;
  margin-top: 15px;
  text-align: center;
  /* border: none; */
  border: 1px solid white;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}

.test{
  max-width: 700px;
  text-align: center;
  margin: auto;
}

.wagerAmt{
  margin-top: 10px;
  font-size: 24px;
}

.score{
  font-family: 'korinna';
  margin-top: 25px;
  font-size: 24px;
}

.finalScoreScreen{
  position: relative;
  top: 95px;
  /* margin-top: 120px; */
  /* margin-top: 2px; */
  /* margin-top: 15px; */
}

.offset{
  /* position: relative;
  bottom: 2px; */
}


.aboutParagraph{
  margin: auto;
  text-align: left;
  padding: 40px;
  font-size: 26px;
  max-width: 800px;
  position: relative;
  /* bottom: 35px; */
}


.dedication{
  /* text-align: center;
  margin: auto; */
  position: relative;
  bottom: 25px;
  font-size: 12px;
  /* position: absolute; */
  /* position: absolute; */
  /* position: relative;
  bottom: 25px; */
}


.refreshButton{
  background: transparent;
  border: none;
  /* color: #0779E4; */
  color: rgba(49,49,233);
  font-weight: bold;
  font-size: 24px;
}

.refreshButton:hover{
  cursor: pointer;
}


.moveOnToDJButton{
  border: 1px solid white;
  border-radius: 5px;
  -webkit-text-stroke-width: .1px;
  -webkit-text-stroke-color: black;
  margin-top: 15px;
  /* background-color: white; */
  font-size: 36px;
  font-family: 'gyparody';
  letter-spacing: 2px;
  margin-right: 10px;
  font-size: 32px;
  border-radius: 5px;
  color: white;
  /* color: rgb(255, 232, 158); */
  border: 1px solid white;
  padding: 10px;
  background-color: rgba(49,49,233);
}

.arrowIcon{
  margin-left: 10px;
  /* color: rgb(255, 232, 158); */
  color: white;
  /* border: 1px solid black; */
}

.valueShadow{
  text-shadow: 3px 3px black;
}

.backToBoardButton{
  height: 45px;
  width: 450px;
  margin-top: 15px;
  background-color: white;
  border: 2px solid #0779E4;
  color: #0779E4;
  font-weight: bold;
  /* border: none;
  font-size: 36px;
  font-family: 'gyparody'; */

}

.moveOnToFJButton{
  border-radius: 5px;
  -webkit-text-stroke-width: .1px;
  -webkit-text-stroke-color: black;
  margin-top: 15px;
  font-size: 36px;
  font-family: 'gyparody';
  letter-spacing: 2px;
  margin-right: 10px;
  font-size: 32px;
  border-radius: 5px;
  border: 1px solid white;
  /* color: rgb(255, 232, 158); */
  color: white;
  padding: 10px;
  background-color: rgba(49,49,233);
}

.moveOnToDJButton:hover{
  cursor: pointer;
}

.moveOnToFJButton:hover{
  cursor: pointer;
}

.loadingNewGame{
  margin-top: 15px;
  font-size: 24px;

}

.loadingBlock{
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.errorMessage{
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.game{
  height: 900px;
}

.backButtonContainer{
  height: 700px;
  top: 112px;
  position: relative;
}

.con{
  position: relative;
}

.aboutButton{
  background: none;
  border: none;
  color: white;
  position: absolute;
  top: 20px;
  right: 12px;
}

.backButton{
  background: none;
  border: none;
  color: white;
  position: absolute;
  top: 20px;
  left: 12px;
}

.aboutButton:hover{
  cursor: pointer;
}

.backButton:hover{
  cursor: pointer;
}

.goToJButton{
  height: 45px;
  width: 450px;
  margin-top: 15px;
  background-color: white;
  border: 2px solid #0779E4;
  color: #0779E4;
  font-weight: bold;
  font-size: 24px;
}

/* .newGameButton{
  -webkit-text-stroke-width: 0.1px;
  -webkit-text-stroke-color: black;
  font-family: 'gyparody';
  color: #0779E4;
  font-size: 32px;
  margin-top: 15px;
  margin-bottom: 15px;
  letter-spacing: 2px;
  background: transparent;
  border: none;
} */

.newGame{
  /* border: 1px solid white; */

  margin-top: 25px;
  font-family: 'korinna';
  background: transparent;
  border: none;
  font-size: 35px;
  border-radius: 5px;
  padding: 10px;
  /* color: #0779E4; */
  color: white;
  border: 1px solid white;

}

.newGame:hover{
  cursor: pointer;
}

.errorMessage{
  margin-top: 25px;
  font-size: 24px;
}

.metal {
  /* border: none; */
  font-family: 'gyparody';
  font-size: 35px;
  margin-top: 15px;
  margin-bottom: 15px;
  letter-spacing: 3px;
  /* border: 1px solid white; */
  background: linear-gradient(
    /* hsl(318, 100%, 51%),
    hsl(239, 50%, 40%) 25%,
    hsl(200, 60%, 50%) 35%,
    hsl(100, 70%, 80%) 45%,
    hsl(60, 100%, 98%) 50%,
    hsl(240, 0%, 0%) 52%,
    hsl(240, 10%, 10%) 60%,
    hsl(240, 50%, 30%) 70%,
    hsl(220, 70%, 60%) 80%,
    hsl(212, 92%, 76%) 85% */
    white,
    /* #A8A9AD,
    #B5B7BB,
    #CCCCCC, */
    white
    /* #757575, */
    /* #AFB1AE */
  );
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  /* -webkit-text-stroke: .5px hsl(318, 100%, 51%); */
}


.raise{
  /* filter:  */
  /* drop-shadow(.5px .5px 0px hsl(242, 54%, 10%)) */
  /* drop-shadow(1px 1px 0px hsl(318, 100%, 51%)) */
  /* drop-shadow(.5px .5px 0px hsl(242, 54%, 20%))
  drop-shadow(.5px .5px 0px hsl(242, 54%, 25%))
  drop-shadow(.5px .5px 0px hsl(242, 54%, 30%)) */
  /* drop-shadow(0px 0px 1px rgba(16, 16, 16, 0.4)); */
}

.subtitle {
  /* ... */
  transform: rotateZ(-10deg);
  color: hsl(318, 100%, 51%); /* pink */ 
  text-shadow: -2px -3px hsl(53, 100%, 81%) /* yellow */, 5px 5px hsl(240, 90%, 61%); /* blue */
}

.jeopardyFont{
  -webkit-text-stroke-width: .1px;
  -webkit-text-stroke-color: black;
  font-family: 'gyparody';
  /* color: #0779E4; */
  font-size: 32px;
  margin-top: 15px;
  margin-bottom: 15px;
  letter-spacing: 2px;
  border: none;
  background: transparent;
}

#loading {
  margin-top: 25px;
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 5px solid rgb(255, 255, 255, .3);
  border-radius: 50%;
  border-top-color:rgba(49,49,233);
  /* border-top-color: #0779E4; */
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}


@keyframes spin {
  to { -webkit-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
  to { -webkit-transform: rotate(360deg); }
}

